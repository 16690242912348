import css from 'styled-jsx/css';

export default css`
  .banner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 3em 1em;
    position: relative;
    flex-direction: column;
    gap: 30px;
    background: linear-gradient(160deg, #e6f2ff 0%, #00e4b812 100%);
  }
  .banner:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background-image: url('https://bdroppy.com/wp-content/uploads/2023/02/sell-designer-fashion-bdroppy-2.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .banner h3 {
    font-size: 24px;
    font-family: 'Poppins';
  }
  @media only screen and (min-width: 768px) {
    .banner {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 8em 0;
      position: relative;
      flex-direction: column;
      gap: 30px;
      background: linear-gradient(160deg, #e6f2ff 0%, #00e4b812 100%);
    }
    .banner h3 {
      font-size: 30px;
    }
    .banner:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.35;
      background-image: url('https://bdroppy.com/wp-content/uploads/2023/02/sell-designer-fashion-bdroppy-2.jpg');
      background-attachment: fixed;
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
`;
