import React, { useEffect } from 'react';

import style from './index.style';
import Icon from 'components/utils/Icon';
import { getFileAddress } from 'tools/utils';
import useTranslation, { mergeTranslation } from 'hooks/useTranslation';
import { Link } from 'components/utils/Link';
import currencyConversion from 'tools/constants/currency_conversion.json';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';
import { getLocaleTranslation, setTranslation } from 'actions/translations';
import { useCallback } from 'react';
import { useState } from 'react';
import Footer from './footer';
import HeaderBanner from './header-banner';
import Header from './header';
import useResponsive from '../../hooks/useResponsive';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function Vetrina({
  userCatalogId,
  username,
  paymentInfo,
  products,
  referralCode,
}) {
  const { TRANSLATIONS } = useTranslation();
  const { isMobile } = useResponsive();
  const router = useRouterWithLang();
  const cart = useSelector((state) => state?.cartProductsVetrina);
  const dispatch = useDispatch();
  const [lang, setLang] = useState();
  const getProduct = useCallback(async () => {
    setLang(paymentInfo?.lang);
  }, [paymentInfo?.lang]);
  useEffect(() => {
    if (userCatalogId && username) {
      getProduct().then();
    }
  }, [getProduct, userCatalogId, username]);

  useEffect(() => {
    let timeoutRef;
    if (paymentInfo?.lang) {
      dispatch(
        getLocaleTranslation(paymentInfo?.lang, (translations) => {
          dispatch(setTranslation(mergeTranslation(translations || {})));
          timeoutRef = setTimeout(() => {}, 500);
        })
      );
    }
    return () => clearTimeout(timeoutRef);
  }, [dispatch, paymentInfo?.lang]);

  return (
    <>
      <Head>
        <title>BDroppy</title>
        <meta property="og:title" content="BDroppy" />
        <meta property="og:type" key="og:type" content="website" />
        <meta name="robots" content="noindex,nofollow" />
        <meta
          property="og:url"
          key="og:url"
          content={`${process.env.BSOCIAL_SHARE_URL}/shop/${username}/${userCatalogId}`}
        />
        <meta
          property="og:image"
          key="og:image"
          content={`${process.env.PHOTO_ADDRESS_SMALL}/${products?.[0]?.pictures?.[0]?.url}`}
        />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
      </Head>
      <Header
        cart={cart}
        username={username}
        userCatalogId={userCatalogId}
        referralCode={referralCode}
        products={products}
      />
      <HeaderBanner username={username} />
      <div className="wrapper-social-ecommerce">
        <div className="product-social ta-c">
          <div className="product-social--wrapper">
            {products?.map((product) => {
              const currencySymbol =
                currencyConversion[product?.currency]?.symbol || '€';
              return (
                <Link
                  href={`/shop/${username}/${userCatalogId}/${product?.code}`}
                  passHref
                  key={product?._id}
                >
                  <div className={'product-social--card d-f fd-c'}>
                    <div>
                      <div className="product-social-img">
                        <img
                          src={getFileAddress(product?.pictures[0])}
                          alt="product image"
                        />
                      </div>
                      <div
                        className={`product-social--info d-f fd-c ${
                          isMobile ? 'g-5' : 'g-10'
                        }`}
                      >
                        <div className="product-social--info__brand">
                          {product?.brand}
                        </div>
                        <div className="product-social--info__subcategory">
                          {`${
                            product?.tags?.find(
                              (tag) => tag.name === 'subcategory'
                            )?.value?.translations?.[lang] ||
                            product?.tags?.find(
                              (tag) => tag.name === 'subcategory'
                            )?.value?.translations?.['en_US'] ||
                            product?.tags?.find(
                              (tag) => tag.name === 'subcategory'
                            )?.value?.value
                          }`}
                        </div>
                        <div
                          className={`d-f jc-sb g-10 ai-c ${
                            isMobile && 'fd-c'
                          }`}
                        >
                          <div className="product-social--info__price">
                            {product?.bestTaxable} {currencySymbol}
                          </div>
                          <div
                            className={`fw-w d-f jc-c ai-c ${
                              isMobile && 'w-100'
                            }`}
                          >
                            {false && (
                              <div
                                className={'cashback d-f g-5 jc-c ai-c fw-w '}
                              >
                                <span>
                                  {TRANSLATIONS.products.list.cashback}
                                </span>
                                <span className={'cashback-amount'}>
                                  {((10 * product?.bestTaxable) / 100).toFixed(
                                    2
                                  )}{' '}
                                  {currencySymbol}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="product-social--btn d-f jc-sb ai-c">
                      <p>{TRANSLATIONS.shop.viewDetail}</p>
                      <Icon type="arrow-right" />
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <Footer referralCode={referralCode} />
      <style jsx>{style}</style>
    </>
  );
}
