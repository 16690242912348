// components/RetainQueryLink.tsx
import Link from 'next/link';
import { useRouter } from 'next/router';
import { parseQueryString } from '../../tools/utils';

const LinkWithLang = ({ href, ...props }) => {
  // 1. use useRouter hook to get access to the current query params
  const router = useRouter();

  const { query, pathname } = parseQueryString(href);

  return (
    <Link
      {...props}
      href={{
        pathname: pathname,
        // combine router.query and query props
        query: {
          ...query,
          lang: router?.query?.lang,
        },
      }}
    />
  );
};
export { LinkWithLang as Link };
