import Vetrina from 'components/vetrina';

function Page({
  paymentInfo,
  products,
  userCatalogId,
  username,
  referralCode,
}) {
  return (
    <Vetrina
      userCatalogId={userCatalogId}
      username={username}
      paymentInfo={paymentInfo}
      products={products}
      referralCode={referralCode}
    />
  );
}
export async function getServerSideProps(context) {
  const { userCatalogId, username } = context.params;
  let response;
  let data;

  try {
    response = await fetch(
      `${process.env.REPROXY_URL}/api/product/${username}/${userCatalogId}`
    );
    data = await response.json();
  } catch (e) {
    console.log('e', e);
    return { props: {} };
  }
  return {
    props: {
      userCatalogId,
      username,
      ...data,
    },
  };
}
export default Page;
