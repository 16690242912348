import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import style from './index.style';

function HeaderBanner({ username }) {
  const { isMobile } = useResponsive();
  const { TRANSLATIONS, locale } = useTranslation();
  return (
    <div className={`banner`}>
      <div className={'relative d-f fd-c g-20 jc-c ta-c'}>
        <h3>{TRANSLATIONS.shop.headerText}</h3>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}

export default HeaderBanner;
